import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

function PortfolioCard({
  title,
  link,
  date,
  children,
  cardNumber,
  expandedCard,
  setExpandedCard,
}) {
  return (
    <div
      className={`${
        cardNumber === 1 ? "featured-portfolio-card" : "portfolio-card"
      } ${expandedCard === cardNumber ? "card-expanded" : ""}`}
      onClick={() => {
        if (expandedCard === cardNumber) {
          setExpandedCard(null);
        } else {
          setExpandedCard(cardNumber);
        }
      }}
    >
      <div className='card-header'>
        <div className='title-container'>
          <h3>
            {title}
            <span className='toggle-arrow'>
              <FontAwesomeIcon
                icon={expandedCard === cardNumber ? faChevronUp : faChevronDown}
              />
            </span>
          </h3>
          {link && (
            <a
              href={link}
              target='_blank'
              rel='noopener noreferrer'
              className='card-link-icon'
              onClick={(e) => e.stopPropagation()}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          )}
        </div>
        <span className='date'>{date}</span>
      </div>
      <div className='card-content'>{children}</div>
    </div>
  );
}

export default PortfolioCard;
